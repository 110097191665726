import { sendGTMEvent } from "@next/third-parties/google";

export enum EcommerceEvent {
  VIEW_ITEM_LIST = "view_item_list",
  SELECT_ITEM = "select_item",
  ADD_TO_CART = "add_to_cart",
  BEGIN_CHECKOUT = "begin_checkout",
  PURCHASE = "purchase"
}
export interface GAItem {
  item_id: string;
  item_name: string;
  affiliation?: string;
  coupon?: string;
  discount?: number;
  index?: number;
  item_brand: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  location_id?: string;
  price: number;
  quantity: number;
  medlemskap?: string;
  senter?: string;
  alder?: string;
  isRecurring?: boolean;
  payAtReception?: boolean;
}

export function viewItemListEvent(itemListId: string, itemListName: string, items: Array<GAItem>) {
  sendGTMEvent({ ecommerce: null });
  sendGTMEvent({
    ecommerce: { currency: "NOK", item_list_id: itemListId, item_list_name: itemListName, items: items },
    event: EcommerceEvent.VIEW_ITEM_LIST
  });
}

export function selectItemEvent(itemListId: string, itemListName: string, items: Array<GAItem>) {
  sendGTMEvent({ ecommerce: null });
  sendGTMEvent({
    ecommerce: { currency: "NOK", item_list_id: itemListId, item_list_name: itemListName, items: items },
    event: EcommerceEvent.SELECT_ITEM
  });
}

export function addToCartEvent(value: number, items: Array<GAItem>, extraInfo?: object) {
  sendGTMEvent({ ecommerce: null });
  sendGTMEvent({
    ecommerce: { currency: "NOK", items: items, value: value },
    event: EcommerceEvent.ADD_TO_CART,
    extraInfo
  });
}

export function beginCheckoutEvent(value: number, items: Array<GAItem>, extraInfo?: object) {
  sendGTMEvent({ ecommerce: null });
  sendGTMEvent({
    ecommerce: { currency: "NOK", items: items, value: value },
    event: EcommerceEvent.BEGIN_CHECKOUT,
    extraInfo
  });
}

export function purchaseEvent(value: number, transactionId: string, items: Array<GAItem>, extraInfo?: object) {
  sendGTMEvent({ ecommerce: null });
  sendGTMEvent({
    ecommerce: { currency: "NOK", items: items, transaction_id: transactionId, value: value },
    event: EcommerceEvent.PURCHASE,
    extraInfo
  });
}

export function sendEvent(extraInfo: object) {
  sendGTMEvent(extraInfo);
}

export function sendLoginEvent() {
  sendGTMEvent({ event: "login", method: "BRP" });
}

export function sendSignupEvent(user_id: string) {
  sendGTMEvent({ event: "sign_up", method: "BRP", user_id: user_id });
}

export function searchEvent(search_term: string, search_hit_id: string, search_hit_link: string) {
  sendGTMEvent({
    event: "search",
    search_hit_id: search_hit_id,
    search_hit_link: search_hit_link,
    search_term: search_term
  });
}

export function shareContentEvent(name?: string, url?: string) {
  sendGTMEvent({
    event: "share",
    name: name,
    url: url
  });
}

import { classNames } from "@/utils/styling-utils";

interface VerticalSpacerProps {
  size?: "xx-small" | "x-small" | "small" | "medium" | "large" | "x-large";
}

const cssClasses = (property: string, value?: string) => {
  if (!value) {
    return;
  }

  const className = `vertical-spacer__${property}--${value}`;
  return className;
};

export const VerticalSpacer = ({ size }: VerticalSpacerProps) => {
  const cssSizeClass = cssClasses("size", size);

  return <div className={classNames("vertical-spacer", cssSizeClass)}></div>;
};

import { RecurrenceType } from "@/generated/client.generated";

// Google Analytics standard event-navn (blir ikke egentlig brukt til noe pt., men Astrid har en plan)
// https://support.google.com/analytics/answer/9267735
export enum GAEventTypes {
  LOGIN = "login",
  PURCHASE = "purchase",
  SEARCH = "search",
  SHARE = "share",
  SIGN_UP = "sign_up"
}

// Ids som identifiserer hvor langt bruker har kommet i forskjellige prosesser
export enum GTMIdTypes {
  // id for hvert form ('side') i bli medlem-prosessen
  BLI_MEDLEM_FORM_ALDERSGRUPPE = "bli-medlem-form-aldersgruppe",
  BLI_MEDLEM_FORM_BETALING = "bli-medlem-form-betaling",
  BLI_MEDLEM_FORM_KVITTERING = "bli-medlem-form-kvittering",
  BLI_MEDLEM_FORM_MEDLEMSKAP = "bli-medlem-form-medlemskap",
  BLI_MEDLEM_FORM_OPPSUMMERING = "bli-medlem-form-oppsummering",
  BLI_MEDLEM_FORM_PERSONALIA = "bli-medlem-form-personalia",
  BLI_MEDLEM_FORM_SENTER = "bli-medlem-form-senter",
  // ids på bli medlem kvittering
  BLI_MEDLEM_KVITTERING = "bli-medlem-kvittering",
  BLI_MEDLEM_KVITTERING_ALDERSGRUPPE = "bli-medlem-kvittering-aldersgruppe",
  BLI_MEDLEM_KVITTERING_MEDLEMSKAP = "bli-medlem-kvittering-medlemskap", // TODO Henriette: endre trigger i GTM etter merge (het "bli-medlem-medlemskap" før)
  BLI_MEDLEM_KVITTERING_PRIS = "bli-medlem-kvittering-pris",
  BLI_MEDLEM_KVITTERING_SENTER = "bli-medlem-kvittering-senter",
  // ids på bli medlem oppsummering
  BLI_MEDLEM_OPPSUMMERING = "bli-medlem-oppsummering",
  BLI_MEDLEM_OPPSUMMERING_ALDERSGRUPPE = "bli-medlem-oppsummering-aldersgruppe",
  BLI_MEDLEM_OPPSUMMERING_MEDLEMSKAP = "bli-medlem-oppsummering-medlemskap",
  BLI_MEDLEM_OPPSUMMERING_PRIS = "bli-medlem-oppsummering-pris",
  BLI_MEDLEM_OPPSUMMERING_SENTER = "bli-medlem-oppsummering-senter",
  BLI_MEDLEM_OPPSUMMERING_TOTAL_PRIS = "bli-medlem-oppsummering-total-pris",
  // ids på booking oppsummering og kvittering
  BOOKING_OPPSUMMERING = "booking-oppsummering",
  BOOKING_OPPSUMMERING_TOTAL_PRIS = "booking-oppsummering-total-pris", // brukes også på kvitteringsiden
  BOOKING_OPPSUMMERING_PRODUKT = "booking-oppsummering-produkt", // brukes også på kvitteringsiden
  BOOKING_KVITTERING = "booking-kvittering"
}

// Ordlyden på disse er bestemt av marked/Astrid
enum GTMRecurrenceTypes {
  BLI_MEDLEM_RECURRENCE_ONE_TIME = "Månedskort", // tren nå
  BLI_MEDLEM_RECURRENCE_RECURRING = "Langtidsmedlemskap" // bli medlem
}

// Returnerer et prefix så GTM kan skille på om det er valgt 'tren nå' eller 'bli medlem'
export const getRecurrenceTypePrefix = (recurrence: RecurrenceType | undefined, wordToPrefix: string) => {
  switch (recurrence) {
    case RecurrenceType.OneTime:
      return `${GTMRecurrenceTypes.BLI_MEDLEM_RECURRENCE_ONE_TIME}: ${wordToPrefix}`;
    case RecurrenceType.Recurring:
      return `${GTMRecurrenceTypes.BLI_MEDLEM_RECURRENCE_RECURRING}: ${wordToPrefix}`;
    default:
      return wordToPrefix;
  }
};

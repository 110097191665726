import { Link } from "@/components/base/link";
import {
  ArrowDownRightIcon,
  CalendarDaysIcon,
  CalendarIcon,
  ChevronRightIcon,
  MapPinIconOutline,
  SearchIconGray,
  UserIconOutlineGray,
  UsersIcon
} from "@/components/icons/icons";
import { HorizontalContainer } from "@/components/layout/horizontal-container";
import { VerticalSpacer } from "@/components/layout/vertical-spacer";
import { Text } from "@/components/ui/text";
import { Page } from "@/generated/client.generated";
import { paths } from "@/utils/paths-utils";
import { capitalizeWord } from "@/utils/string-utils";
import { Hit as AlgoliaHit } from "instantsearch.js";
import { VerticalContainer } from "../layout/vertical-container";
import { useRouter } from "next/navigation";
import React, { useContext } from "react";
import { SearchContext } from "@/contexts/search-context";
import { lockBodyWhenModalIsOpen } from "@/utils/modal-utils";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { searchEvent, sendEvent } from "@/analytics/event-logger";
import { useSearchBox } from "react-instantsearch";

//løst koblet med SearchType i SearchManager i backend
export type Hit = AlgoliaHit<{
  objectID: string;
  model: string;
  id: string;
  slug: string;
  page?: Page;
}>;

type HitProps = {
  hit: Hit;
};

export function SearchHit({ hit }: HitProps) {
  const { query } = useSearchBox();

  const router = useRouter();
  const { setSearchModalActive } = useContext(SearchContext);
  if (!hit.page?.title) {
    return null;
  }

  let link = "";
  let hitIcon = <SearchIconGray size="small" />;
  switch (hit.model) {
    case "landing-page": {
      link = hit.id;
      break;
    }
    case "center": {
      link = hit.slug ? paths.senter(hit.slug) : paths.senters;
      hitIcon = <MapPinIconOutline color="gray" size="small" />;
      break;
    }
    case "activity": {
      link = hit.slug ? paths.activity(hit.slug) : paths.allActivities;
      hitIcon = <CalendarIcon size="small" color="gray" />;
      break;
    }
    case "event": {
      link = hit.slug ? paths.event(hit.slug) : paths.events;
      hitIcon = <CalendarDaysIcon size="small" color="gray" />;
      break;
    }
    case "instructor": {
      link = hit.slug ? paths.instruktor(hit.slug) : "";
      hitIcon = <UsersIcon size="small" color="gray" />;
      break;
    }
    case "min-side": {
      link = hit.id;
      hitIcon = <UserIconOutlineGray size="small" color="gray" />;
      break;
    }
  }

  const breadcrumbs = `${link}`.split("/").flatMap((crumb) => (crumb === "/" || crumb === "" ? [] : crumb));

  return (
    <div
      key={hit.objectID}
      className="search-hit"
      data-insights-object-id={hit.objectID}
      data-insights-position={hit.__position}
      data-insights-query-id={hit.__queryID}
    >
      <Link
        id={hit.id}
        href={link}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            lockBodyWhenModalIsOpen(false);
            setSearchModalActive(false);
            router.push(link);
            sendEvent({ event: "Search Result Clicked" });
          }
        }}
        textDecoration="none"
        onClick={() => {
          searchEvent(query, hit.id, link);
          lockBodyWhenModalIsOpen(false);
          setSearchModalActive(false);
          sendEvent({ event: "Search Result Clicked" });
        }}
      >
        <VerticalContainer>
          <HorizontalSpacer size="small" />

          <div className="search-hit--title">
            <HorizontalContainer verticalPlacement="center" noWrap>
              <VerticalSpacer size="x-small" />
              {hitIcon}
              <VerticalSpacer size="x-small" />
              <Text value={hit.page.title} truncate />
              <VerticalSpacer size="x-small" />
            </HorizontalContainer>
          </div>
          {breadcrumbs.length > 2 && (
            <HorizontalContainer verticalPlacement="center" noWrap>
              <VerticalSpacer size="x-large" />
              <ArrowDownRightIcon size="x-small" color="gray" />
              {/* fjern sånn at de i midten fjernes så det bare blir en med ... */}
              {breadcrumbs.flatMap((crumb, index) => {
                if (
                  breadcrumbs.length > 3 &&
                  index > 0 &&
                  index !== breadcrumbs.length - 1 &&
                  index !== breadcrumbs.length - 2
                ) {
                  return [];
                }
                if (index === breadcrumbs.length - 1) {
                  return (
                    <React.Fragment key={index}>
                      <Text
                        value={index === 0 || index === breadcrumbs.length - 1 ? capitalizeWord(crumb) : "..."}
                        size="small"
                        color="gray"
                        truncate
                      />
                      {index !== breadcrumbs.length - 1 && <ChevronRightIcon size="x-small" color="gray" />}
                    </React.Fragment>
                  );
                }
                return (
                  <React.Fragment key={index}>
                    <Text
                      key={index}
                      value={index === 0 || index === breadcrumbs.length - 1 ? capitalizeWord(crumb) : "..."}
                      size="small"
                      color="gray"
                    />
                    {index !== breadcrumbs.length - 1 && <ChevronRightIcon size="x-small" color="gray" />}
                  </React.Fragment>
                );
              })}
              <VerticalSpacer size="large" />
            </HorizontalContainer>
          )}
          <HorizontalSpacer size="small" />
        </VerticalContainer>
      </Link>
    </div>
  );
}

"use client";

import { ReactNode, SyntheticEvent } from "react";
import { default as LinkNext } from "next/link";

import { classNames } from "@/utils/styling-utils";
import { GAEventTypes } from "@/utils/ga-utils";

interface LinkProps {
  ariaLabel?: string;
  children: ReactNode;
  /**
   * dataComponent is for Google Tag Manager
   */
  dataComponent?: string;
  /**
   * dataEvent is for Google Tag Manager
   */
  dataEvent?: GAEventTypes;
  /**
   * dataLabel is for Google Tag Manager
   */
  dataLabel?: string;
  href?: string;
  id?: string;
  newTab?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  textDecoration?: "none"; // 'underline' er default
  prefetch?: boolean;
}

export const Link = ({
  ariaLabel,
  children,
  dataComponent,
  dataEvent,
  dataLabel,
  href,
  id,
  newTab,
  onClick,
  onKeyDown,
  textDecoration,
  prefetch = true
}: LinkProps) => (
  <LinkNext
    aria-label={ariaLabel}
    className={classNames("link", textDecoration && `link__text-decoration--${textDecoration}`)}
    data-component={dataComponent}
    data-event={dataEvent}
    data-label={dataLabel}
    href={href || ""}
    id={id}
    onClick={onClick && onClick}
    target={newTab ? "_blank" : "_self"}
    title={ariaLabel}
    prefetch={prefetch}
    onKeyDown={onKeyDown}
  >
    {children}
  </LinkNext>
);

"use client";

type StorageType = "SESSION" | "LOCAL";
type UseStorageReturnValue = {
  getItem: <T>(key: string, type: StorageType) => T | null;
  setItem: <T>(key: string, storageType: StorageType, value: T) => boolean;
  removeItem: (key: string, type: StorageType) => void;
};
const storageUtils = (): UseStorageReturnValue => {
  function getItem<T>(key: string, storageType: StorageType): T | null {
    const isBrowser: boolean = ((): boolean => typeof window !== "undefined")();
    if (!isBrowser) {
      return null;
    }
    switch (storageType) {
      case "SESSION": {
        const data: string | null = sessionStorage.getItem(key);
        if (!data) return null;
        return JSON.parse(data) as T;
      }
      case "LOCAL": {
        const data: string | null = localStorage.getItem(key);
        if (!data) return null;
        return JSON.parse(data) as T;
      }
    }
  }

  function setItem<T>(key: string, storageType: StorageType, value: T): boolean {
    const isBrowser: boolean = ((): boolean => typeof window !== "undefined")();
    if (!isBrowser) return false;
    switch (storageType) {
      case "SESSION":
        sessionStorage.setItem(key, JSON.stringify(value));
        break;
      case "LOCAL":
        localStorage.setItem(key, JSON.stringify(value));
        break;
    }
    return true;
  }

  function removeItem(key: string, storageType: StorageType): void {
    const isBrowser: boolean = ((): boolean => typeof window !== "undefined")();
    if (!isBrowser) return;
    switch (storageType) {
      case "SESSION":
        sessionStorage.removeItem(key);
        break;
      case "LOCAL":
        localStorage.removeItem(key);
        break;
    }
  }

  return {
    getItem,
    removeItem,
    setItem
  };
};

export default storageUtils;

"use client";
import { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react";

interface SearchContextProps {
  searchModalActive: boolean;
  setSearchModalActive: Dispatch<SetStateAction<boolean>>;
}

export const SearchContext = createContext<SearchContextProps>({
  searchModalActive: false,
  setSearchModalActive: () => Promise.resolve
});

interface SearchProviderProps {
  children?: ReactNode;
}
export function SearchProvider({ children }: SearchProviderProps) {
  const [searchModalActive, setSearchModalActive] = useState<boolean>(false);
  return (
    <SearchContext.Provider
      value={{
        searchModalActive,
        setSearchModalActive
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

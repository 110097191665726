import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/vercel/path0/frontend/src/clients/apollo-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/base/link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Menu"] */ "/vercel/path0/frontend/src/components/navigation/menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Search"] */ "/vercel/path0/frontend/src/components/search/search.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthContext"] */ "/vercel/path0/frontend/src/contexts/auth-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchProvider"] */ "/vercel/path0/frontend/src/contexts/search-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/vercel/path0/frontend/src/contexts/user-context.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/styles/globals.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/styles/main.scss");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/vercel/path0/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Mulish\",\"arguments\":[{\"display\":\"swap\",\"preload\":true,\"subsets\":[\"latin\"]}],\"variableName\":\"mulish\"}");

import { ReactNode } from "react";

import { classNames } from "@/utils/styling-utils";
import { LayoutContainerBaseProps } from "./layout.model";
import ContentLoader from "react-content-loader";

interface VerticalContainerProps extends LayoutContainerBaseProps {
  backgroundColor?: "gray";
  children: ReactNode;
}

const cssClasses = (property: string, value?: string) => {
  if (!value) {
    return;
  }

  const className = `vertical-container__${property}--${value}`;
  return className;
};

export const VerticalContainer = ({
  backgroundColor,
  children,
  height,
  horizontalPlacement,
  verticalPlacement,
  width
}: VerticalContainerProps) => {
  const cssAlignClass = cssClasses("vertical-placement", verticalPlacement);
  const cssHeightClass = cssClasses("height", height);
  const cssJustifyClass = cssClasses("horizontal-placement", horizontalPlacement);
  const cssWidthClass = cssClasses("width", width);

  return (
    <div
      className={classNames(
        "vertical-container",
        backgroundColor && `vertical-container__background-color--${backgroundColor}`,
        cssAlignClass,
        cssHeightClass,
        cssJustifyClass,
        cssWidthClass
      )}
    >
      {children}
    </div>
  );
};

export const VerticalContainerSkeleton = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={115}
    viewBox="0 0 400 150"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <circle cx="10" cy="20" r="8" />
    <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
    <circle cx="10" cy="50" r="8" />
    <rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
    <circle cx="10" cy="80" r="8" />
    <rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
  </ContentLoader>
);

"use client";

import { ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";

import { classNames } from "@/utils/styling-utils";
import { ChevronDownIcon } from "../icons/icons";
import { useIsDesktop } from "@/hooks/use-is-desktop";
import { usePathname } from "next/navigation";
import { MenuContext } from "@/contexts/menu-context";

interface MenuSectionProps {
  children: ReactNode;
  title: string;
  url?: string;
}

export const MenuSection = ({ children, title, url }: MenuSectionProps) => {
  const { currentOpenMenuSection, setCurrentOpenMenuSection } = useContext(MenuContext);
  const pathname = usePathname();
  const [localMenuIsOpen, setLocalMenuIsOpen] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState(false);
  const isDesktop = useIsDesktop();
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!currentOpenMenuSection) {
      setLocalMenuIsOpen(false); // lukker alle lokale menyer ved klikk utenfor
    }

    if (localMenuIsOpen) {
      if (currentOpenMenuSection !== title) {
        setLocalMenuIsOpen(false); // lukker andre lokale menyer som ikke er denne
      }
    }
  }, [localMenuIsOpen, currentOpenMenuSection, title]);

  useEffect(() => {
    if (url) {
      const urlFirstSegment = url.split("/").filter(Boolean)[0];
      const pathnameFirstSegment = pathname.split("/").filter(Boolean)[0];
      const otherSectionIsOpen = currentOpenMenuSection && currentOpenMenuSection !== title;
      setIsSelected(urlFirstSegment === pathnameFirstSegment && !otherSectionIsOpen);
    }
  }, [url, pathname, currentOpenMenuSection, title, localMenuIsOpen, isSelected]);

  useEffect(() => {
    if (!isDesktop) {
      if (localMenuIsOpen) {
        setTimeout(() => {
          if (buttonRef.current) {
            buttonRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 50);
      }
    }
  }, [isDesktop, localMenuIsOpen]);

  const toggleMenu = useCallback(() => {
    setLocalMenuIsOpen(!localMenuIsOpen);
    setCurrentOpenMenuSection(title);
  }, [localMenuIsOpen, title, setCurrentOpenMenuSection]);

  return (
    <>
      <div className="menu-section">
        <button
          ref={buttonRef}
          className={classNames(
            "menu-section__button",
            ((currentOpenMenuSection === title && localMenuIsOpen) || isSelected) && "menu-section__button--selected"
          )}
          onClick={toggleMenu}
        >
          {title}
          <div
            className={classNames(
              "menu-section__button__chevron",
              localMenuIsOpen && "menu-section__button__chevron--rotated"
            )}
          >
            <ChevronDownIcon size="small" />
          </div>
        </button>

        {localMenuIsOpen && <div className="menu-section__content">{children}</div>}
      </div>
    </>
  );
};
